<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 1184px">
      <div class="layer_head">
        <strong class="tit_popup">지출정산서 전표내역</strong>
      </div>
      <div
        class="layer_body"
        style="height: calc(100vh - 270px); max-height: 608px; min-height: 49px"
      >
        <PopStatementVoucherItem
          v-for="(item, index) in certificateList"
          :key="index"
          :index="index"
          :certificateDesc="item.certificateDesc"
          :proofType="item.proofType"
          :rowData="item.voucher"
        />
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button type="button" class="btn_medium btn_primary" @click.prevent="onClickSelected">
            전표생성
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopStatementVoucherItem from "./PopStatementVoucherItem";

export default {
  name: "PopStatementVoucher",
  components: {
    PopStatementVoucherItem,
  },
  props: {
    certificateList: Array,
  },
  methods: {
    onClickSelected() {
      this.$emit("onClickComplete");
    },
  },
};
</script>
<style>
.inner_popup_layer {
  padding: 0;
}
.inner_popup_layer .layer_head,
.inner_popup_layer .layer_body {
  margin: 0 32px;
}
.inner_popup_layer .layer_body {
  padding-bottom: 32px;
  border-top: 1px solid #222;
}
.inner_popup_layer .layer_foot {
  position: relative;
  z-index: 1;
  padding: 20px 0;
  box-shadow: 0 -2px 8px 0 rgba(34, 34, 34, 0.15);
}
</style>
